export const OS_TYPE = {
  windows: /win/,
  macos: /mac/,
  linux: /linux/,
  android: /android/,
  ios: /iphone|ipad|ipod/,
}

export const HINTS = {
  newChat: {
    mac: '⌘+,',
    nonMac: 'ctrl+,',
  },
  history: {
    mac: '⌘+H',
    nonMac: 'ctrl+H',
  },
  chatSetting: {
    mac: '⌘+.',
    nonMac: 'ctrl+.',
  },
  cyborg: {
    mac: '⌘+1',
    nonMac: 'ctrl+1',
  },
  docQuestion: {
    mac: '⌘+2',
    nonMac: 'ctrl+2',
  },
  drFinder: {
    mac: '⌘+3',
    nonMac: 'ctrl+3',
  },
  provCalc: {
    mac: '⌘+4',
    nonMac: 'ctrl+4',
  },
  seafarer: {
    mac: '⌘+5',
    nonMac: 'ctrl+5',
  },
}
export const GENERIC_DOMAINS = new Set([
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'msn.com',
])
export const PWC_PILOT_ACCOUNT_ID = '8c5f4720-aacf-419c-9ff0-67e5233b8b7f'
