import MultiSelect from 'components/form/multiSelect/multiSelect'
import useCallAPI from 'components/hooks/callApi'
import {
  AccountNav,
  Animate,
  Button,
  Card,
  EmailInput,
  TitleRow,
  useAPI,
  useNavigate,
  usePermissions,
  ViewContext,
} from 'components/lib'
import { useContext, useState } from 'react'
import { cn } from 'utils'

const INITIAL_FORM_DATA = {
  email: { value: '', valid: undefined },
  permission: { value: [], valid: true },
  licenses: { value: [], valid: true },
}

const InviteUsersForm = ({ productResponse }) => {
  const navigate = useNavigate()
  const context = useContext(ViewContext)
  const [formData, setFormData] = useState([{ ...INITIAL_FORM_DATA }])
  const permissions = usePermissions()

  const [callInviteUsers, _, loading] = useCallAPI({
    url: '/api/invite/bulk',
    method: 'POST',
  })
  const permissionOptions = permissions?.data?.list?.filter(
    (x) => x.value !== 'owner' && x.value !== 'developer' && x.value !== 'manager_core_concept'
  )

  const handleChange = (index, field, value, valid) => {
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData]
      updatedFormData[index][field] = { value, valid }
      return updatedFormData
    })
  }
  const addFormFields = () => {
    setFormData((prev) => [...prev, { ...INITIAL_FORM_DATA }])
  }

  const removeFormFields = (index) => {
    setFormData((prevFormData) => prevFormData.filter((_, i) => i !== index))
  }

  const validate = () => {
    return formData.every((entry) => entry.email?.valid && entry.email.value.length > 0)
  }

  const handleSubmit = async (e) => {
    if (!validate()) return

    const users = formData.map((user) => ({
      email: user.email.value,
      licenses: user.licenses.value,
      permission: user.permission.value.length > 0 ? user.permission.value[0] : 'user',
    }))
    const res = await callInviteUsers({
      requestData: {
        users,
      },
    })
    if (res && !res.isAxiosError) {
      context.notification.show('Invitation sent successfully', 'success', true)
      navigate('/account/users')
    }
  }

  const getAvailableLicenses = (currentIndex) => {
    if (!formData || !productResponse?.data) return []
    const selectedLicenseCounts = formData.reduce((acc, entry, index) => {
      if (index !== currentIndex) {
        entry.licenses.value.forEach((licenseId) => {
          acc[licenseId] = (acc[licenseId] || 0) + 1
        })
      }
      return acc
    }, {})

    return productResponse?.data?.reduce((availableOptions, option) => {
      const totalAssigned = option.assigned_license + (selectedLicenseCounts[option.id] || 0)
      const availableCount = option.purchased_quantity - totalAssigned

      if (availableCount > 0) {
        availableOptions.push({
          label: option.name,
          value: option.id,
        })
      }

      return availableOptions
    }, [])
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      {formData.map((entry, index) => (
        <div key={index} className="form-entry border-b last:border-b-0 ">
          <div className="flex gap-2 w-full my-2">
            <div className="flex-1">
              <EmailInput
                label="Email"
                id={`email-${index}`}
                name={`email-${index}`}
                placeholder="Enter your email"
                value={entry.email.value}
                valid={entry.email.valid}
                onChange={(input, value, valid) => handleChange(index, 'email', value, valid)}
                required
              />
            </div>
            <MultiSelect
              singleSelect
              options={permissionOptions || []}
              className="flex-1"
              label="Permission"
              selectedValues={entry.permission.value?.length > 0 ? entry.permission.value : ['user']}
              onValueChange={(value) => handleChange(index, 'permission', value, value.length > 0)}
              placeholder="Select permission"
            />
          </div>
          <div className="flex gap-4 items-center">
            <MultiSelect
              options={getAvailableLicenses(index)}
              className="flex-1"
              id={`licences-${index}`}
              label="Licence"
              selectedValues={entry.licenses.value}
              onValueChange={(value) => handleChange(index, 'licenses', value, value.length > 0)}
              placeholder="Select licence"
            />
            <Button
              icon="trash"
              disabled={formData.length <= 1}
              iconSize={16}
              iconColor={formData?.length > 1 ? '#EF4444' : '#ef444468'}
              action={() => removeFormFields(index)}
            />
          </div>
        </div>
      ))}
      <Button
        textOnly
        icon="plus"
        disabled={formData.length >= 10}
        className={cn(' font-semibold mt-4 mb-8', formData.length < 10 ? '!text-brand-500' : '!text-brand-500/50')}
        text="Add"
        action={addFormFields}
      />
      <Button
        small
        className="mt-4"
        text={'Submit'}
        action={handleSubmit}
        color="brand3"
        loading={loading}
        disabled={!validate()}
      />
    </form>
  )
}

export const Invite = () => {
  const productResponse = useAPI('/api/assigned-product-list')

  return (
    <>
      <AccountNav />
      <Animate>
        <TitleRow title="Invite Users">
          <Button small text="Back" goto="/account/users" color="brand3" />
        </TitleRow>
        <Card loading={productResponse?.loading}>
          <InviteUsersForm productResponse={productResponse} />
        </Card>
      </Animate>
    </>
  )
}

export default Invite
