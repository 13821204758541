import Checkbox from 'components/form/newCheckbox'
import useCallAPI from 'components/hooks/callApi'
import { Button, Drawer, useAPI, ViewContext, AuthContext } from 'components/lib'
import { ProfileIcon } from 'icons'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const UserProfile = ({ userId }) => {
  const [callGetUser, userResponse, loading] = useCallAPI({
    url: `/api/user/${userId}`,
  })
  useEffect(() => {
    callGetUser()
  }, [])
  const user = userResponse?.data
  if (loading) return <>loading...</>
  return (
    <div className="flex items-center gap-4 mb-4">
      <ProfileIcon className="mb-auto" />
      <div>
        <h3 className="font-semibold text-2xl text-primary-dark-blue">{user?.name}</h3>
        <p className="text-sm font-medium ">{user?.email}</p>
        <p className="text-sm font-medium ">{user?.permission}</p>
        <div className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-2 py-1 mt-5 uppercase rounded">
          {user?.verified ? 'Verified' : 'Registered'}
        </div>
      </div>
    </div>
  )
}

const DrawerContent = ({ onOpenChange }) => {
  const context = useContext(ViewContext)
  const authContext = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('userId')
  const [assignProduct, _, submitLoading] = useCallAPI({
    url: '/api/assign-product',
    method: 'POST',
  })
  const [getAssignProducts, assignedLicense] = useCallAPI({ url: `api/user/licenses/${userId}` })
  const initialAssignedIds = new Set(assignedLicense?.data?.map((a) => a.account_plans_id))
  const productResponse = useAPI('/api/assigned-product-list')
  const [products, setProducts] = useState(productResponse.data || [])

  const [selectedLicenses, setSelectedLicenses] = useState({})

  useEffect(() => {
    getAssignProducts()
  }, [])

  useEffect(() => {
    const initialSelectedLicenses = productResponse?.data?.reduce((acc, product) => {
      acc[product.id] = initialAssignedIds.has(product.id)
      return acc
    }, {})
    setSelectedLicenses(initialSelectedLicenses)
  }, [productResponse, assignedLicense])

  useEffect(() => {
    const tempProductResponse = productResponse.data || []
    setProducts(tempProductResponse)
  }, [productResponse])

  const handleLicenseToggle = (productId) => {
    setSelectedLicenses((prev) => {
      const newState = { ...prev }
      newState[productId] = !newState[productId]
      return newState
    })

    setProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product.id === productId) {
          const isNowSelected = !selectedLicenses[productId]
          return {
            ...product,
            assigned_license: isNowSelected ? product.assigned_license + 1 : product.assigned_license - 1,
          }
        }
        return product
      })
    })
  }

  const handleSave = async () => {
    const assignedPriceIds = []
    const unassignedPriceIds = []

    for (const [id, isSelected] of Object.entries(selectedLicenses)) {
      const wasAssigned = initialAssignedIds.has(id)
      if (isSelected && !wasAssigned) {
        assignedPriceIds.push(id)
      } else if (!isSelected && wasAssigned) {
        unassignedPriceIds.push(id)
      }
    }
    if (assignedPriceIds.length > 0 || unassignedPriceIds.length > 0) {
      const res = await assignProduct({
        requestData: {
          assignedTo: userId,
          assignedPriceIds,
          unassignedPriceIds,
        },
      })
      if (res && !res.isAxiosError) {
        context.notification.show('Licenses were assigned', 'success', true)
        onOpenChange(false)
        authContext.refreshAuth()

        return
      }
      context.notification.show(res.message, 'error', true)
    }
  }

  const hasChanges = useCallback(() => {
    for (let id in selectedLicenses) {
      const isSelected = selectedLicenses[id]
      const wasAssigned = initialAssignedIds.has(id)
      if (isSelected !== wasAssigned) {
        return true
      }
    }
    return false
  }, [selectedLicenses, initialAssignedIds])

  return (
    <>
      <UserProfile userId={userId} />
      <h4 className="font-semibold text-gray-800 mt-4 mb-2">Licenses</h4>
      {productResponse?.loading ? (
        <div className="flex justify-center items-center h-36">loading...</div>
      ) : products && products?.length <= 0 ? (
        <div className="flex justify-center flex-col gap-2 items-center h-36">
          <h2>No data found </h2>
          <Button small text="Purchase License" goto={'/account/products'} color={'brand3'} />
        </div>
      ) : (
        <>
          <div className="h-[calc(100vh-20rem)] overflow-y-auto">
            {products?.map((product, id) => (
              <div key={product.price_id + id} className="flex items-center justify-between">
                <div className="flex-1">
                  <Checkbox
                    item={{
                      value: product.price_id,
                      label: product.name,
                      checked: selectedLicenses[product.id] || false,
                      disabled: selectedLicenses[product.id]
                        ? false
                        : product.purchased_quantity - product.assigned_license <= 0,
                    }}
                    onChange={() => handleLicenseToggle(product.id)}
                  />
                </div>
                <span className="text-sm text-gray-500">
                  {product.purchased_quantity - product.assigned_license} of {product.purchased_quantity} available
                </span>
              </div>
            ))}
          </div>
          <Button
            disabled={!hasChanges()}
            small
            loading={submitLoading}
            fullWidth
            className="mt-5"
            text="Save"
            action={handleSave}
            color={'brand3'}
          />
        </>
      )}
    </>
  )
}
const AssignLicense = ({ open, onOpenChange }) => {
  return (
    <Drawer open={open} onOpenChange={onOpenChange} title="Manage License" width="28rem">
      <DrawerContent onOpenChange={onOpenChange} />
    </Drawer>
  )
}

export default AssignLicense
